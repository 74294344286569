import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Techniczna optymalizacja. Kiedy witryna jest przyjazna dla robotów?",
        description:
          "Aby strona internetowa osiągała wysokie pozycje w wynikach wyszukiwania, musi być przystępna dla robotów Google. Sprawdź, o jakie funkcjonalności musisz zadbać. ",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id":
                  "/baza-wiedzy/artykul/optymalizacja-seo-strony-co-zrobic-aby-witryna-byla-przystepna-dla-robotow/",
                name: "Techniczna optymalizacja. Kiedy witryna jest przyjazna dla robotów?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/optymalizacja-seo-strony-co-zrobic-aby-witryna-byla-przystepna-dla-robotow",
            text: "Optymalizacja SEO strony. Co zrobić, aby witryna była przystępna dla robotów?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt={"Optymalizacja SEO strony. Co zrobić, aby witryna była przystępna dla robotów?"}
          />
        }
      >
        <h1>Optymalizacja SEO strony. Co zrobić, aby witryna była przystępna dla robotów?</h1>
        <p>
          <strong>
            Strona internetowa, aby osiągnąć wysoką pozycję w wyszukiwarce, musi być odpowiednio przygotowana pod
            względem SEO. Optymalizacja strony jest zagadnieniem szerokim i składa się z wielu działań. Jednym z nich
            jest sprawdzenie, w jaki sposób roboty Google widzą daną witrynę, a następnie wprowadzenie na niej
            korzystnych zmian. Dzięki temu strona WWW będzie bardziej przystępna dla robotów wyszukiwarki, a co za tym
            idzie, także dla swoich potencjalnych użytkowników. Zobacz, o jakie funkcjonalności należy zadbać.
          </strong>
        </p>
        <h2>Czym są robots.txt i meta robots?</h2>
        <p>
          Podczas dostosowywania danej strony pod wymogi wyszukiwarki jednym z bardzo ważnych działań jest zadbanie o
          dostępność robotów do strony, a także przekazanie im o niej kluczowych informacji. Służą do tego robots.txt
          oraz meta robots.{" "}
        </p>
        <p>
          <strong>Robots.txt</strong> to plik, przekazujący robotom informacje, do jakich zasobów mogą mieć dostęp, a do
          których nie. Plik ten daje różne możliwości, jednak kluczowe jest sprawdzenie, czy nie ma on żadnych błędów.
          Zablokowanie całej strony bądź jej części w pliku robots.txt może powodować znaczne obniżenie potencjału
          witryny lub nawet całkowite pozbawienie widoczności w wyszukiwarce.
        </p>
        <p className="inserted-seo-link">
          Przeczytaj,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            czym jest audyt SEO
          </a>{" "}
          strony. Dowiedz się już teraz!
        </p>
        <p>
          Plik robots.txt umieszczany jest w katalogu głównym na serwerze strony. Przy użyciu tego pliku możliwe jest
          zarządzanie ruchem robotów indeksujących w danej witrynie. Jak więc zadbać o odpowiednią optymalizację pliku
          robots.txt? Należy wprowadzić do niego podstawowe dyrektywy i gotowy plik dodać do katalogu głównego witryny.
          Zawarte w robots.txt polecenia dla robotów wyszukiwarki, umożliwiają im lub ograniczają dostęp do konkretnych
          zasobów. Są to wytyczne dla robotów, które zazwyczaj są przez nie respektowane, jednak warto dodać, że czasami
          może być inaczej. Wszystko zależy już od samych robotów.{" "}
        </p>
        <p>
          Dlaczego plik robots.txt może być przydatny? Przede wszystkim umożliwia w bardzo szybki sposób ograniczenie
          dostępu robotów do określonych sekcji strony, jeśli nie mogły one być wykluczone w inny sposób. To z kolei
          powoduje, że roboty nie tracą swoich zasobów na skanowanie podstron o niskiej wartości pod kątem SEO, takich
          jak na przykład podstrona logowania do serwisu.
        </p>
        <p>
          <strong>Meta Robots</strong> (meta tag robots) to dane, które przekazują ważne informacje o danej stronie
          internetowej dla robotów. Tak samo, jak w przypadku robots.txt, niezbędne jest tutaj sprawdzenie, czy cała
          witryna lub jej część nie została zablokowana przed indeksacją. Blokada może spowodować, że strona w ogóle nie
          znajdzie się w wyszukiwarce Google! Jest to bardzo poważny błąd, gdyż w takim wypadku wszystkie pozostałe
          działania SEO nie przyniosą żadnego efektu. Taka sytuacja często ma miejsce podczas publikacji nowej strony.
          Stronę produkcyjną bowiem zwyczajowo wyklucza się z indeksacji i jeśli zdarzy się, że deweloper zapomni o jej
          odblokowaniu podczas publikacji, pojawia się wówczas spory problem.
        </p>
        <p>
          Za pomocą różnego rodzaju meta tagów robots można zarządzać procesem indeksacji. Google rozumie pewne
          konkretne wartości. Podstawowe dyrektywy w meta robots dotyczą kwestii indeksacji witryny i żeby je określić,
          należy umieścić odpowiednie instrukcje. Najpierw trzeba użyć jednej z dwóch dyrektyw:{" "}
        </p>
        <ul>
          <li>index – umożliwia indeksowanie strony,</li>
          <li>noindex – uniemożliwia indeksowanie strony,</li>
        </ul>
        <p>a następnie, po przecinku instrukcję dotyczącą linków na stronie:</p>
        <ul>
          <li>follow – umożliwia robotom podążanie za odnośnikami,</li>
          <li>nofollow – uniemożliwia robotom podążanie za odnośnikami.</li>
        </ul>
        <p>Dla przykładu strona o pełnej dostępności dla robotów będzie miała następująco wyglądający tag:</p>
        <p>&lt;meta name="robots" content="index, follow"&gt;,</p>
        <p>a całkowicie zablokowana:</p>
        <p>&lt;meta name="robots" content="noindex, nofollow"&gt;.</p>
        <p>
          Warto dodać, że jeżeli tag w ogóle nie występuje na stronie, to roboty interpretują to domyślnie jako
          dyrektywy index, follow.
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Jakie przekierowania 301 należy ustawić?</h2>
        <p>
          Przekierowanie to sposób, za pomocą którego serwer danej strony internetowej informuje przeglądarkę o
          konieczności przeniesienia się pod inny adres, by mieć dostęp do treści. Najpopularniejszym rodzajem
          przekierowania jest przekierowanie 301, które jest wyjątkowo ważne pod kątem SEO, ponieważ pozwala przenieść
          „moc” przekierowanej podstrony na nową stronę docelową, co pomaga m.in. w pozbyciu się duplikatów, które
          obniżają wartość strony i jej pozycję w wyszukiwarce.{" "}
        </p>
        <p className="inserted-seo-link">
          Zleć zrobienie <a href="/">audytu strony internetowej</a> i popraw jej widoczność w wyszukiwarce.{" "}
        </p>
        <h2>Przekierowanie WWW / bez WWW</h2>
        <p>
          Każda domena strony internetowej może występować w dwóch wariantach – z przedrostkiem WWW oraz bez niego. Dla
          robotów wyszukiwarek są to dwie oddzielne strony, a jeśli jest na nich identyczna zawartość, wyszukiwarka
          zinterpretuje to jako kopię treści, co może niekorzystnie wpłynąć na SEO.
        </p>
        <p>
          Jak zatem rozwiązać ten problem? Konieczne jest ustawienie odpowiedniego przekierowania 301. Dzięki temu
          unikniemy duplikacji treści, a użytkownik bez względu na to, czy wpisze w pasek wyszukiwarki nasz adres z WWW,
          czy bez, zostanie przeniesiony na dokładnie tę samą stronę docelową. Najpopularniejszym sposobem - chociaż nie
          jedynym - jest ustawienie przekierowania 301 w pliku .htaccess, który jest umieszczony w katalogu głównym na
          serwerze. W pliku tym należy umieścić konkretną gotową regułę.{" "}
        </p>
        <h2>Przekierowanie index.php / index.html</h2>
        <p>
          Często zdarza się, że strona główna serwisu wyświetlana jest nie tylko w adresie głównym domeny, ale również
          na innych podstronach. W zależności od silnika strony może ona występować pod różnymi adresami, a do
          najpopularniejszych należą: /index.html i /index.php. Jeżeli taka sytuacja występuje, mamy do czynienia z
          wewnętrzną kopią treści, która także może niekorzystnie wpływać na SEO. Rozwiązaniem tego problemu jest
          odpowiednie ustawienie przekierowania 301 lub ustawienie linku kanonicznego, jeśli przekierowanie nie jest
          możliwe ze względów technicznych.
        </p>
        <h2>Przekierowanie adresu IP na domenę</h2>
        <p>
          Niektóre serwery są skonfigurowane tak, że po wpisaniu w pasku przeglądarki adresu IP danego serwera, strona
          internetowa będzie dostępna dla użytkowników i robotów indeksujących. Niestety to również może prowadzić do
          duplikacji treści. Jeśli okaże się, że Twoja strona jest duplikowana na adresie IP, konieczne będzie wdrożenie
          przekierowania owego adresu na domenę. W innych przypadkach przekierowanie nie jest konieczne, a często jest
          nawet niemożliwe do wykonania (jeśli na danym adresie iP znajduje się więcej niż jedna domena).
        </p>
        <h2>Czym jest certyfikat SSL?</h2>
        <p>
          Czynnikiem pozytywnie wpływającym na pozycję strony internetowej w wyszukiwarce jest także certyfikat SSL
          (ang. Secure Socket Layer). Jest to protokół sieciowy wdrażany na stronę w celu szyfrowania danych
          przesyłanych przez Internet. Gwarantuje użytkownikowi bezpieczeństwo i poufność przekazywanych informacji,
          takich jak np. dane osobowe, loginy, hasła czy numery kart kredytowych. Google wspiera bezpieczne dla
          odbiorców strony i umieszcza je na wyższej pozycji, dlatego certyfikat SSL jest istotny z punktu widzenia SEO.
        </p>
        <p>
          Strona z certyfikatem SSL określa połączenie jako bezpieczne, a przez przeglądarki jest oznaczana w pasku
          adresu poprzez symbol zamkniętej kłódki oraz przedrostek HTTPS. W tym miejscu należy zaznaczyć, że nie można
          zapomnieć też o ustawieniu przekierowania strony z wersji HTTP na HTTPS.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/przystepnosc-dla-robotow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
